import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { GET_SHORTEN_URL } from './query';
import loader from "../../assets/loader.png"
const ShareProductComponent = () => {
    const { shortId } = useParams();
    const navigate = useNavigate();

    const { data, loading } = useQuery(GET_SHORTEN_URL, {
        variables: { shortId: shortId },
        fetchPolicy: "cache-and-network",
    })

    useEffect(() => {
        if (data?.getRedirectURL?.redirectURL) {
            window.location.href = data.getRedirectURL.redirectURL;
        } else {
            if (!loading) {
                window.location.href = '/';
            }
        }
    }, [data, navigate, loading])

    return (
        <>
            {loading && <div style={{ height: '100vh', display: 'flex' }}>
                <img className="loader-image" src={loader} alt="Loading" />
            </div>}
        </>
    );
};

export default ShareProductComponent;
